import { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { Box, Link, useMediaQuery, Skeleton, useTheme } from '@mui/material';

import { useQuery } from '@tanstack/react-query';

import { getAllClaims } from 'services/middleware/auth';

import cloudinaryLoader from 'utils/loaderUtils';

Logo.propTypes = {
  link: PropTypes.string,
};

function Logo({
  link = '/',
  whiteLabelDisposition = 'horizontal',
  logoType = 'full',
}) {
  const MUITheme = useTheme();
  const mobileBreakpoint = useMediaQuery(MUITheme.breakpoints.down('sm'));
  const [imageNotFound, setImageNotFound] = useState(false);
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  const baseR2Url = `${process.env.R2_HOST}/common/companies`;

  const { data: claims, isFetching } = useQuery({
    queryKey: [`bidding-logo`],
    queryFn: getAllClaims,
    staleTime: 1,
    initialData: {},
    refetchOnWindowFocus: false,
    enabled: !hasBeenCalled,
    onSuccess: () => {
      setHasBeenCalled(true);
    },
  });

  if (isFetching) {
    return <Skeleton height={40} width={160} variant="rectangular" />;
  }

  const hasCompanyLogo = claims?.company_identifier && !imageNotFound;

  return (
    <Box
      component="h2"
      m={0}
      height={40}
      display="flex"
      flexDirection={whiteLabelDisposition === 'horizontal' ? 'row' : 'column'}
      alignItems={
        whiteLabelDisposition === 'vertical' && hasCompanyLogo ? 'center' : null
      }
      width={mobileBreakpoint ? '100%' : null}
      sx={{
        marginBottom:
          hasCompanyLogo && whiteLabelDisposition === 'vertical'
            ? '36px'
            : null,
      }}
    >
      <Link id="logo" underline="none" href={link} title="Página Inicial">
        {!mobileBreakpoint ||
        logoType === 'full' ||
        imageNotFound ||
        !hasCompanyLogo ? (
          <Box
            borderRight={{
              xs: 'none',
              md:
                whiteLabelDisposition === 'horizontal' && hasCompanyLogo
                  ? `1px solid #B3B3B3`
                  : 'none',
            }}
            paddingRight={whiteLabelDisposition === 'horizontal' ? 2 : 0}
            height="100%"
          >
            <Image
              loader={cloudinaryLoader}
              alt="logotipo Fluencypass"
              height={40}
              priority
              quality={50}
              src="fluency_pass_logotipo"
              width={160}
            />
          </Box>
        ) : (
          <Image
            loader={({ src, width }) => `${src}?w=${width}`}
            alt="logotipo Fluencypass"
            height={40}
            priority
            quality={50}
            src="/assets/svg/minimal-logo.svg"
            width={40}
          />
        )}
      </Link>

      {!isFetching && hasCompanyLogo && !imageNotFound && (
        <Image
          alt="White Label company logo"
          height={34}
          loader={({ src, width }) => `${src}?w=${width}`}
          src={`${baseR2Url}/${claims?.company_identifier}/light-bidding-logo.svg`}
          title="Bidding Class Logo"
          width={112}
          style={{
            marginLeft: whiteLabelDisposition === 'horizontal' ? '16px' : null,
            flex: 1,
          }}
          onError={() => setImageNotFound(true)}
        />
      )}
    </Box>
  );
}

export default Logo;
